.category-item {
    padding: 10px;
    cursor: pointer;
    /*text-align: center;*/
}

.category-content {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    transition: transform 0.2s;
}

.category-item:hover .category-content {
    transform: scale(1.05);
}

.category-icon {
    font-size: 2em;
}

.category-name {
    margin-top: 10px;
    font-size: 1.2em;
    font-weight: bold;
}

.slick-prev:before, .slick-next:before {
    color: black;
}

.category-content.selected {
    border: 2px solid #007bff;
    background-color: #e9ecef;
    font-weight: bold;
}

.product-card {
    border: 1px solid #d5d5d5;
    border-radius: 15px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s; */
}

/* .product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
} */

.product-image {
    height: 200px;
    object-fit: cover;
}



.product-title {
    /* font-size: 1.2em; */
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.product-price {
    /* font-size: 1em; */
    font-size: 14px;
    color: #666;
}

/* .product-card .btn-primary {
    background-color: #007bff;
    border: none;
} */

.product-card .btn-primary:hover {
    background-color: #0056b3;
}

h2 {
    margin: 20px 10px;
}


.search-bar {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}

.search-bar input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    outline: none;
    width: 300px;
}

.search-bar button {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-left: none;
    background-color: #007bff;
    color: white;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.search-bar button:hover {
    background-color: #0056b3;
}

.weekly-featured .slick-prev {
    /* top: -110px;
    left: auto;
    right: 35px !important;
    z-index: 10; */
}
.weekly-featured .slick-next{
    /* top: -110px;
    right: 0px !important;

    z-index: 10; */
}
/* Ensure the slider container uses the full width */
.slick-slider {
    display: flex;
    align-items: center;
}

/* Ensure individual slide items take full width */
.slick-slide {
    flex: 1 !important;
    display: flex !important;
    justify-content: center !important;
}

/* Optional: Add margin to slide items if necessary */
.slick-slide > div {
    margin: 0 10px;
    width: -webkit-fill-available;

}

/* Adjust for fewer items */
.slick-list {
    padding: 0 !important;
    margin: 0 auto;
}

.slick-track {
    display: flex;
    align-items: center;
}

@media (max-width: 600px) {
    .slick-prev,
    .slick-next {
        /* display: none !important; */
    }
    .slick-next{
        right: -5px;
    }
    .slick-prev{
        left: -5px;
    }
    
}