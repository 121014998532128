/*
 ** Author: Santosh Kumar Dash
 ** Author URL: http://santoshdash.epizy.com/
 ** Github URL: https://github.com/quintuslabs/fashion-cube
 */

@charset "utf-8";
/* CSS Document */

/******************************

COLOR PALETTE




[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Home
	3.1 Currency
	3.2 Language
	3.3 Account
	3.4 Main Navigation
	3.5 Hamburger Menu
	3.6 Logo
	3.7 Main Slider
4. Banner
5. New Arrivals
	5.1 New Arrivals Sorting
	5.2 New Arrivals Products Grid
6. Deal of the week
7. Best Sellers
	7.1 Slider Nav
8. Benefit
9. Blogs
10. Newsletter
11. Footer



******************************/
/***********
0. Colors
***********/
:root {
  --primary-color: #6e0000;
  --secondary-color: #4e97fd;
  --black-color: #000000;
  --White-color: #fff;
  --brand-color: #747c80;
  --price-color: #e4573d;
  --Products-title-color: #2e3133;
  --button-blue-color: #0777b9;
  --lightGray-color: #f3f4f6;
  --logoColor: #E4573D;
}

/***********
1. Fonts
***********/

/* Bold Fonts */
@font-face {
  /*---------- Inter ExtraBold ----------*/
  /* font-family: 'Bold';
    src: url('../fonts/Inter/static/Inter-ExtraBold.ttf') format('truetype'); */

  /*---------- Sf-Pro-Bold ----------*/
  /* font-family: 'Bold';
    src: url('../fonts/sf-pro/SFPRODISPLAYBOLD.OTF') format('truetype'); */

  /*---------- Satoshi-Bold ----------*/
  font-family: 'Bold';
  src: url('../fonts/satoshi/Satoshi-Bold.otf') format('truetype');
}

/* Regular Fonts */
@font-face {
  /*---------- Inter-Regular ----------*/
  /* font-family: 'Regular';
    src: url('../fonts/Inter/static/Inter-Regular.ttf') format('truetype'); */

  /*---------- Sf-Pro-Regular ----------*/
  /* font-family: 'Regular';
    src: url('../fonts/sf-pro/SFPRODISPLAYREGULAR.OTF') format('truetype'); */

  /*---------- Satoshi-Regular ----------*/
  font-family: 'Regular';
  src: url('../fonts/satoshi/Satoshi-Regular.otf') format('truetype');
}

/* Medium Fonts */
@font-face {
  /*---------- Inter-Medium ----------*/
  /* font-family: 'Medium';
    src: url('../fonts/Inter/static/Inter-Medium.ttf') format('truetype'); */

  /*---------- Sf-Pro-Medium ----------*/
  /* font-family: 'Medium';
    src: url('../fonts/sf-pro/SFPRODISPLAYMEDIUM.OTF') format('truetype'); */

  /*---------- Satoshi-Medium ----------*/
  font-family: 'Medium';
  src: url('../fonts/satoshi/Satoshi-Medium.otf') format('truetype');
}



.sf-Regular {
  font-family: "Regular" !important;
}

.sf-Medium {
  font-family: "Medium" !important;

}

.sf-Bold {
  font-family: "Bold" !important;
}

.fontSize10 {
  font-size: 10;
}

.fontSize14 {
  font-size: 14px !important;
}

.inter-medium-fonts {
  font-family: "Medium";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2e3133;
  font-family: "Bold" !important;
  /*font-weight: bold;*/
}

.interBold {
  color: #2e3133;
  font-family: "Bold";
  font-weight: bold;
}

.sidebar-filter-section {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 1.45rem;
  font-weight: 600;
  letter-spacing: -0.025rem;
  font-family: "Regular";
}

.sidebar-filter-section-List {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Medium";
  color: var(--secondary-color);
}

.tab-title {
  font-family: "Regular";
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  /* color: */

}


h5 {
  font-size: 1.5rem !important;
  line-height: 2rem;

}

h5.title {
  font-weight: bold;
  letter-spacing: -0.03rem;
  font-family: "Bold" !important;
}

h3.titles {
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 500;
  letter-spacing: -0.025rem;
  font-family: "Regular", sans-serif;
}

.read-more {
  display: inline-block;
  position: relative;
  font-size: 1rem !important;
  line-height: 1.5rem;
  font-weight: 500;
  color: var(--secondary-color);
}

.secondaryColor {
  color: var(--secondary-color) !important
}

.secondaryBG {
  background-color: var(--secondary-color) !important;
}

.secondaryBGColor {
  background-color: var(--button-blue-color) !important;
  border-color: var(--button-blue-color) !important;
  ;
}

.titleColor {
  color: var(--Products-title-color)
}

.button-blue-color {
  background: var(--button-blue-color);
}

.trashIconColor {
  color: var(--Products-title-color) !important;
}

.trashIconColor:hover {
  color: var(--secondary-color) !important
}

.custom-auto-height {
  height: auto !important;
}

@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,900");

/*********************************
2. Body and some general stuff
*********************************/

* {
  margin: 0;
  padding: 0;
}

.PrimaryBGColor {
  background-color: var(--primary-color);
}

.priceLabelColor {
  color: var(--price-color) !important;
}

.blackColor {
  color: var(--black-color);
}

.lightGrayBGColor {
  background-color: var(--lightGray-color);
}

.brandLabel {
  color: var(--brand-color);
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: start;
}

.productDetailBrandLabel {
  color: #333;
}

.priceLabel {
  position: relative;
  /* font-size: 1.25rem; */
  font-size: 14px;
  line-height: 2rem;
  font-weight: bold;
  color: var(--price-color);
  display: inline-block;
}

.normalPriceLabel {
  position: relative;
  /* font-size: 1.25rem; */
  font-size: 14px;
  line-height: 2rem;
  font-weight: bold;
  color: black;
  display: inline-block;
}

.actualPrice {
  font-size: 14px;
  text-decoration: line-through;
  padding: 0 9px;
  font-weight: 400;
  color: var(--brand-color);
}

body {
  /* "Poppins", sans-serif; */

  color: #9B9B9B;
  font-family: "Regular" !important;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.74;
}

div {
  display: block;
  /*position: relative;*/
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin-bottom: 0px;
}

p {
  /* font-family: "Poppins", sans-serif; */
  font-family: "Regular" !important;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
  color: #989898;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

p a {
  display: inline;
  position: relative;
  color: inherit;
  border-bottom: solid 2px #fde0db;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

a,
a:hover,
a:visited,
a:active,
a:link {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

p a:active {
  position: relative;
  color: #ff6347;
}

p a:hover {
  color: #ff6347;
  background: #fde0db;
}

p a:hover::after {
  opacity: 0.2;
}

::selection {
  background: #fde0db;
  color: #ff6347;
}

p::selection {
  background: #fde0db;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #282828;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection {}

::-webkit-input-placeholder {
  font-size: 16px !important;
  font-weight: 500;
  color: #777777 !important;
}

:-moz-placeholder

/* older Firefox*/
  {
  font-size: 16px !important;
  font-weight: 500;
  color: #777777 !important;
}

::-moz-placeholder

/* Firefox 19+ */
  {
  font-size: 16px !important;
  font-weight: 500;
  color: #777777 !important;
}

:-ms-input-placeholder {
  font-size: 16px !important;
  font-weight: 500;
  color: #777777 !important;
}

::input-placeholder {
  font-size: 16px !important;
  font-weight: 500;
  color: #777777 !important;
}

.form-control {
  color: #db5246;
}

section {
  display: block;
  position: relative;
  box-sizing: border-box;
}

.clear {
  clear: both;
}

.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}

.clearfix::after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

.float_left {
  float: left;
}

.float_right {
  float: right;
}

.trans_200 {
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.trans_300 {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.trans_400 {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.trans_500 {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.fill_height {
  height: 100%;
}

.super_container {
  width: 100%;
  overflow: hidden;
}

.hide {
  display: none;
  transform: translate(9999px);
  transition: all 1s linear;
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(165, 164, 164);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(167, 167, 167);
}

/*********************************
3. Home
*********************************/
.layout-Container {
  min-height: calc(100vh - (150px + 1em));
  min-width: 100vw;
  margin-top: 150px;
}

.header {
  /*position: fixed;*/
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 10;
}

.top_nav {
  width: 100%;
  height: 60px;
  background: #1e1e27;
  transition: all 2s linear;
  padding: 10px 20px;
}

.top_nav_left {
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  color: #b5aec4;
  text-transform: uppercase;
}

.bottomHeaderBG {
  background-color: var(--secondary-color) !important;
}

/*********************************
3.1 Currency
*********************************/

.currency {
  display: inline-block;
  position: relative;
  min-width: 50px;
  border-right: solid 1px #33333b;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  vertical-align: middle;
  background: #1e1e27;
}

.currency>a {
  display: block;
  color: #b5aec4;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  text-transform: uppercase;
}

.currency>a>i {
  margin-left: 8px;
}

.currency:hover .currency_selection {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.currency_selection {
  display: block;
  position: absolute;
  right: 0;
  top: 120%;
  margin: 0;
  width: fit-content;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 0 25px rgba(63, 78, 100, 0.15);
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}

.currency_selection li {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 50px;
}

.currency_selection li a {
  display: block;
  color: #232530;
  border-bottom: solid 1px #dddddd;
  font-size: 13px;
  text-transform: uppercase;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}

.currency_selection li a:hover {
  color: #b5aec4;
}

.currency_selection li:last-child a {
  border-bottom: none;
}

/*********************************
3.2 Language
*********************************/

.language {
  display: inline-block;
  position: relative;
  min-width: 50px;
  border-right: solid 1px #33333b;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  vertical-align: middle;
  background: #1e1e27;
}

.language>a {
  display: block;
  color: #b5aec4;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
}

.language>a>i {
  margin-left: 8px;
}

.language:hover .language_selection {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.language_selection {
  display: block;
  position: absolute;
  right: 0;
  top: 120%;
  margin: 0;
  width: 100%;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 0 25px rgba(63, 78, 100, 0.15);
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}

.language_selection li {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 50px;
}

.language_selection li a {
  display: block;
  color: #232530;
  border-bottom: solid 1px #dddddd;
  font-size: 13px;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}

.language_selection li a:hover {
  color: #b5aec4;
}

.language_selection li:last-child a {
  border-bottom: none;
}

/*********************************
3.3 Account
*********************************/

.account {
  display: inline-block;
  position: relative;
  min-width: 50px;
  padding-left: 20px;
  text-align: center;
  vertical-align: middle;
  background: #1e1e27;
}

.account>a {
  display: block;
  color: #b5aec4;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
}

.account>a:hover {
  text-decoration: none;
}

.account>a>i {
  margin-left: 8px;
}

.account:hover .account_selection {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.account_selection {
  display: block;
  position: absolute;
  right: 0;
  top: 120%;
  margin: 0;
  width: 100%;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 0 25px rgba(63, 78, 100, 0.15);
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}

.account_selection li {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 50px;
}

.account_selection li a {
  display: block;
  color: #232530;
  border-bottom: solid 1px #dddddd;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.account_selection li a:hover {
  color: #b5aec4;
  text-decoration: none;
}

.account_selection li:last-child a {
  border-bottom: none;
}

.account_selection li a i {
  margin-right: 10px;
}

/*********************************
3.4 Main Navigation
*********************************/

.main_nav_container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
}

.navbar {
  width: auto;
  height: 100px;
  float: right;
  padding-left: 0px;
  padding-right: 0px;
}

.navbar_menu li {
  display: inline-block;
}

.navbar_menu li a {
  display: block;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 20px;
  text-decoration: none;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.navbar_menu li a:hover {
  color: #b5aec4;
  text-decoration: none;
}

.navbar_user {
  margin-left: 37px;
}

.navbar_user li {
  display: inline-block;
  text-align: center;
}

.navbar_user li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 40px;
  height: 40px;
  color: #1e1e27;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.navbar_user li a:hover {
  color: #b5aec4;
  text-decoration: none;
}

.checkout a {
  background: #eceff6;
  border-radius: 50%;
}

.checkout_items {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*position: absolute;*/
  /*top: 15px;*/
  /* right: 65px; */
  margin-left: 9px;
  margin-top: -9px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fe4c50;
  font-size: 12px;
  color: #ffffff;
}

/*********************************
3.5 Hamburger Menu
*********************************/

.hamburger_container {
  display: none;
  margin-left: 40px;
}

.hamburger_container i {
  font-size: 24px;
  color: #1e1e27;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.hamburger_container:hover i {
  color: #b5aec4;
}

.hamburger_menu {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.95);
  z-index: 10;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.hamburger_menu.active {
  right: 0;
}

.fs_menu_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.hamburger_close {
  position: absolute;
  top: 26px;
  right: 10px;
  padding: 10px;
  z-index: 1;
}

.hamburger_close i {
  font-size: 24px;
  color: #1e1e27;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.hamburger_close:hover i {
  color: #b5aec4;
}

.hamburger_menu_content {
  padding-top: 100px;
  width: 100%;
  height: 100%;
  padding-right: 20px;
}

.menu_item {
  display: block;
  position: relative;
  border-bottom: solid 1px #b5aec4;
  vertical-align: middle;
}

.menu_item>a {
  display: block;
  color: #1e1e27;
  font-weight: 500;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  text-transform: uppercase;
}

.menu_item>a:hover {
  color: #b5aec4;
}

.menu_item>a>i {
  margin-left: 8px;
}

.menu_item.active .menu_selection {
  display: block;
  visibility: visible;
  opacity: 1;
}

.menu_selection {
  margin: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.menu_selection li {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 50px;
}

.menu_selection li a {
  display: block;
  color: #232530;
  border-bottom: solid 1px #dddddd;
  font-size: 13px;
  text-transform: uppercase;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}

.menu_selection li a:hover {
  color: #b5aec4;
}

.menu_selection li:last-child a {
  border-bottom: none;
}

/*********************************
3.6 Logo
*********************************/

.logo_container {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  /* left: 15px; */
}

.logo_container a {
  font-size: 24px;
  color: #1e1e27;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}

.logo_container a span {
  color: #fe4c50;
  text-decoration: none;
}

.logo_container a:hover {
  color: #1e1e27;
  text-decoration: none;
}

/*********************************
3.7 Main Slider
*********************************/

.main_slider {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.main_slider_content {
  width: 60%;
}

.main_slider_content h6 {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 29px;
}

.main_slider_content h1 {
  font-weight: 400;
  line-height: 1;
}

.red_button {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 40px;
  background: var(--secondary-color) !important;
  border-radius: 3px;
  font-family: "Regular" !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.red_button:hover {
  background: var(--logoColor) !important;
}

.red_button a {
  display: block;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 40px;
  width: 100%;
}

.shop_now_button {
  width: 140px;
  margin-top: 32px;
}

.red_button.disabled {
  background-color: #ccc;
  /* Gray out the button */
  cursor: not-allowed;
}

/*********************************
4. Banner
*********************************/

.banner {
  width: 100%;
  margin-top: 30px;
}

.banner_item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 265px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.banner_category {
  height: 50px;
  background: #ffffff;
  min-width: 180px;
  padding-left: 25px;
  padding-right: 25px;
}

.banner_category a {
  display: block;
  color: #1e1e27;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  line-height: 50px;
  width: 100%;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.banner_category a:hover {
  color: #b5aec4;
}

/*********************************
5. New Arrivals
*********************************/

.new_arrivals {
  width: 100%;
}

.new_arrivals_title {
  margin-top: 74px;
}

/*********************************
5.1 New Arrivals Sorting
*********************************/

.new_arrivals_sorting {
  display: inline-block;
  border-radius: 3px;
  margin-top: 59px;
  overflow: visible;
}

.grid_sorting_button {
  height: 40px;
  min-width: 102px;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  border: solid 1px #ebebeb;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background: #ffffff;
  border-radius: 3px;
  margin: 0;
  float: left;
  margin-left: -1px;
}

.grid_sorting_button:first-child {
  margin-left: 0px;
}

.grid_sorting_button:last-child {}

.grid_sorting_button.active:hover {
  background: #fe7c7f !important;
}

.grid_sorting_button.active {
  color: #ffffff;
  background: #fe4c50;
}

/*********************************
5.2 New Arrivals Products Grid
*********************************/

.product-grid {
  width: 100%;
  margin-top: 57px;
}

.product-item {
  height: 375px;
  cursor: pointer;
}

.product-item::after {
  display: block;
  position: absolute;
  top: 0;
  left: -1px;
  width: calc(100% + 1px);
  height: 100%;
  pointer-events: none;
  content: "";
  border: solid 2px rgba(235, 235, 235, 0);
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-item:hover::after {
  box-shadow: 0 25px 29px rgba(63, 78, 100, 0.15);
  border: solid 2px rgba(235, 235, 235, 1);
}

.product {
  width: 100%;
  height: 100%;
  /*background-color: #f2f2f261;*/
}

.product_image {
  width: 100%;
  height: 190px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
}

.product_image img {
  max-width: 100%;
  max-height: 100%;
  height: 125px;
  width: auto;
  object-fit: contain;
}

.product_info {
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.product_name {
  margin-top: 27px;
}

.product_name a {
  color: #1e1e27;
  line-height: 20px;
}

.product_name:hover a {
  color: #b5aec4;
}

.discount .product_info .product_price {
  font-size: 14px;
}

.product_price {
  font-size: 24px !important;
  color: #fe4c50;
  font-weight: 600;
}

.product_price span {
  font-size: 12px;
  margin-left: 10px;
  color: #b5aec4;
  text-decoration: line-through;
}

.product_bubble {
  position: absolute;
  top: 15px;
  width: 50px;
  height: 22px;
  border-radius: 3px;
  text-transform: uppercase;
}

.product_bubble span {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.product_bubble_right::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 6px;
  right: auto;
  top: auto;
  bottom: -6px;
  border: 6px solid;
}

.product_bubble_left::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 6px;
  bottom: -6px;
  border: 6px solid;
}

.product_bubble_red::after {
  border-color: transparent transparent transparent #fe4c50;
}

.product_bubble_left::after {
  border-color: #51a042 #51a042 transparent transparent;
}

.product_bubble_red {
  background: #fe4c50;
}

.product_bubble_green {
  background: #51a042;
}

.product_bubble_left {
  left: 15px;
}

.product_bubble_right {
  right: 15px;
}

.add_to_cart_button {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.add_to_cart_button a {
  font-size: 12px;
  font-weight: 600;
}

.product-item:hover .add_to_cart_button {
  visibility: visible;
  opacity: 1;
}

.favorite {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #b9b4c7;
  width: 17px;
  height: 17px;
  visibility: hidden;
  opacity: 0;
}

.favorite_left {
  left: 15px;
}

.favorite.active {
  visibility: visible;
  opacity: 1;
}

.product-item:hover .favorite {
  visibility: visible;
  opacity: 1;
}

.favorite.active::after {
  color: #fe4c50;
}

.favorite:hover {
  color: #fe4c50;
}

/*********************************
6. Deal of the week
*********************************/

.deal_ofthe_week {
  width: 100%;
  margin-top: 41px;
  background: #f2f2f2;
}

.deal_ofthe_week_img {
  height: 540px;
}

.deal_ofthe_week_img img {
  height: 100%;
}

.deal_ofthe_week_content {
  height: 100%;
}

.section_title {
  display: inline-block;
  text-align: center;
}

.section_title h2 {
  display: inline-block;
}

.section_title::after {
  display: block;
  position: absolute;
  top: calc(100% + 13px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 60px;
  height: 5px;
  background: #fe4c50;
  content: "";
}

.timer {
  margin-top: 66px;
}

.timer li {
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #ffffff;
  margin-right: 10px;
}

.timer li:last-child {
  margin-right: 0px;
}

.timer_num {
  font-size: 48px;
  font-weight: 600;
  color: #fe4c50;
  margin-top: 10px;
}

.timer_unit {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #51545f;
}

.deal_ofthe_week_button {
  width: 140px;
  background: #1e1e27;
  margin-top: 52px;
}

.deal_ofthe_week_button:hover {
  background: #2b2b34 !important;
}

/*********************************
7. Best Sellers
*********************************/

.product_slider_container {
  width: 100%;
  height: 340px;
  margin-top: 73px;
}

.product_slider_container::after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #ffffff;
  content: "";
  z-index: 1;
}

.product_slider {
  overflow: visible !important;
}

.product_slider_item .product-item {
  height: 340px;
}

.product_slider_item {
  width: 20%;
}

.product_slider_item .product-item {
  width: 100% !important;
}

/*********************************
7.1 Slider Nav
*********************************/

.product_slider_nav {
  position: absolute;
  width: 30px;
  height: 70px;
  background: #d3d3d6;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product_slider_container:hover .product_slider_nav {
  visibility: visible;
  opacity: 1;
}

.product_slider_nav i {
  color: #ffffff;
  font-size: 12px;
}

.product_slider_nav_left {
  top: 50%;
  left: 0;
}

.product_slider_nav_right {
  top: 50%;
  right: 0;
}

/*********************************
8. Benefit
*********************************/

.benefit {
  margin-top: 74px;
}

.benefit_row {
  background-color: #f3f3f3;
}

.benefit_col {
  padding-left: 0px;
  padding-right: 0px;
}

.benefit_item {
  height: 100px;
  background: #f3f3f3;
  border-right: solid 1px #ffffff;
  padding-left: 25px;
}

.benefit_col:last-child .benefit_item {
  border-right: none;
}

.benefit_icon i {
  font-size: 30px;
  color: #fe4c50;
}

.benefit_content {
  padding-left: 22px;
}

.benefit_content h6 {
  text-transform: uppercase;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}

.benefit_content p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0px;
  color: #51545f;
}

/*********************************
4. Breadcrumbs
*********************************/

.breadcrumbs {
  width: 100%;
  height: 100px;
  border-bottom: solid 0px #ebebeb;
  margin-bottom: 79px;
  text-align: left;
}

.breadcrumbs ul li {
  display: inline-block;
  margin-right: 17px;
}

.breadcrumbs ul li.active a {
  color: #b9b4c7;
}

.breadcrumbs ul li i {
  margin-right: 17px;
}

.breadcrumbs ul li a {
  color: #51545f;
}

/*********************************
9. single product
*********************************/
.prductsListBorder {
  /*border: 1px solid #f9f9f9*/
}

.single_product_container {
  margin-top: 50px;
  padding-bottom: 80px;
  border-bottom: solid 1px #ebebeb;
}

.single_product_thumbnails {
  /* height: 527px; */
}

.single_product_thumbnails ul li {
  position: relative;
  height: 130px;
  margin-bottom: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.single_product_thumbnails ul li::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 1px #fe4c50;
  content: "";
  visibility: hidden;
}

.single_product_thumbnails ul li.active::after,
.single_product_thumbnails ul li:hover::after {
  visibility: visible;
}

.single_product_thumbnails ul li img {
  width: auto;
  text-align: center;
  max-width: 130px;
  max-height: 130px;
}

.image_col {
  padding-right: 30px;
}

.single_product_image {
  width: 100%;
  height: 527px;
}

.single_product_image_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

/*********************************
6. Product Details
*********************************/

.product_details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.product_details_title {
  font-weight: 500px;
  line-height: 30px;
}

.product_details_title p {
  margin-top: 12px;
}

.free_delivery {
  background: #f5f5f5;
  margin-top: 53px;
}

.free_delivery i {
  display: inline-block;
}

.free_delivery span:last-child {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
}

.free_delivery span:first-child {
  display: inline-block;
  margin-right: 19px;
  font-size: 16px;
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
}

.original_price {
  font-weight: 500;
  color: #b9b4c7;
  text-decoration: line-through;
  line-height: 30px;
  margin-top: 21px;
}

.product_price {
  display: inline-block;
  font-size: 24px !important;
  color: #fe4c50;
  font-weight: 700;
  /* line-height: 30px; */
  margin-top: 2px;
}

.star_rating {
  display: inline-block;
  margin-left: 33px;
  width: 20%;
}

.star_rating li {
  display: inline-block;
  margin-right: -2px;
  cursor: pointer;
}

.star_rating li i {
  color: #fac451;
}

.product_color {
  margin-top: 27px;
}

.product_color ul {
  display: inline-block;
  margin-left: 26px;
}

.product_color ul li {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 29px;
  cursor: pointer;
}

.quantity {
  width: 100%;
  height: 40px;
  margin-top: 23px;
}

.quantity_selector {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 132px;
  height: 100%;
  border: solid 1px #e5e5e5;
  /* margin-left: 29px; */
}

.quantity_selector span {
  font-weight: 500;
  color: #232530;
}

.plus,
.minus {
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
}

.plus:hover,
.minus:hover {
  color: #b5aec4;
}

.add_to_cart_button {
  font-size: 12px !important;
}

.product_favorite {
  width: 40px;
  height: 100%;
  margin-left: 22px;
  border: solid 1px #e5e5e5;
  border-radius: 2px;
  cursor: pointer;
}

.product_favorite:hover::after {
  color: #fe4c50;
}

.red_button.product-add_to_cart_button {
  display: block;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  line-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 45px;
  margin-top: 50px !important;
  /* margin-left: 20px; */
}

.red_button.product-add_to_cart_button a:hover {
  text-decoration: none;
  color: white;
}

.red_cart_button.product_add_to_cart_icon {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 40px;
  background: #fe4c50;
  color: white;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s ease;
}

span.minus.disabled {
  color: #b5aec4;
  pointer-events: none;
  cursor: none;
}

span.plus.disabled {
  color: #b5aec4;
  pointer-events: none;
  cursor: none;
}

/*********************************
10. Newsletter
*********************************/

.newsletter {
  width: 100%;
  background: #f2f2f2;
  margin-top: 81px;
}

.newsletter_text {
  height: 120px;
}

.newsletter_text h4 {
  margin-bottom: 0px;
  line-height: 24px;
}

.newsletter_text p {
  margin-bottom: 0px;
  line-height: 1.7;
  color: #51545f;
}

.newsletter_form {
  height: 120px;
}

#newsletter_email {
  width: 300px;
  height: 46px;
  background: #ffffff;
  border: none;
  padding-left: 20px;
}

#newsletter_email:focus {
  border: solid 1px #7f7f7f !important;
  box-shadow: none !important;
}

:focus {
  outline: none !important;
}

#newsletter_email::-webkit-input-placeholder {
  font-size: 14px !important;
  font-weight: 400;
  color: #b9b4c7 !important;
}

#newsletter_email:-moz-placeholder

/* older Firefox*/
  {
  font-size: 14px !important;
  font-weight: 400;
  color: #b9b4c7 !important;
}

#newsletter_email::-moz-placeholder

/* Firefox 19+ */
  {
  font-size: 14px !important;
  font-weight: 400;
  color: #b9b4c7 !important;
}

#newsletter_email:-ms-input-placeholder {
  font-size: 14px !important;
  font-weight: 400;
  color: #b9b4c7 !important;
}

#newsletter_email::input-placeholder {
  font-size: 14px !important;
  font-weight: 400;
  color: #b9b4c7 !important;
}

.newsletter_submit_btn {
  width: 160px;
  height: 46px;
  border: none;
  background: #fe4c50;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}

.newsletter_submit_btn:hover {
  background: #fe7c7f;
}

/*********************************
11. Footer
*********************************/

footer.footer {
  margin-top: 200px;
  background: #f2f2f28a;
}

.footer_nav_container {
  height: 130px;
}

.cr {
  margin-right: 48px;
}

.footer_nav li {
  display: inline-block;
  margin-right: 45px;
}

.footer_nav li:last-child {
  margin-right: 0px;
}

.footer_nav li a,
.cr {
  font-size: 14px;
  color: #51545f;
  line-height: 30px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.footer_nav li a:hover,
.cr a,
.cr i {
  color: #fe4c50;
}

.footer_social {
  height: 130px;
}

.footer_social ul li {
  display: inline-block;
  margin-right: 10px;
}

.footer_social ul li:last-child {
  margin-right: 0px;
}

.footer_social ul li a {
  color: #51545f;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  padding: 10px;
}

.footer_social ul li:last-child a {
  padding-right: 0px;
}

.footer_social ul li a:hover {
  color: #fe4c50;
}

a:hover {
  text-decoration: none;
}

.mega-drop-down {
  display: inline-block;
  position: relative;
  min-width: 50px;
  padding-left: 20px;
  text-align: center;
  vertical-align: middle;
}

.mega-menu {
  /* background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 0 25px rgba(63, 78, 100, 0.15);
  transition: all 0.3s ease;
  padding: 10px 15px 10px 15px;
  padding-top: 0;
  position: absolute; */
}

.mega-drop-down:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  top: 100%;
  box-sizing: content-box;
}

.mega-menu-wrap {
  display: flex;
  flex-direction: row;
}

.mega-menu-content {
  text-align: left;
  margin-right: 20px;
  margin-top: 20px;
}

ul.stander {
  flex-direction: column;
  display: flex;
}

ul.stander li a {
  padding: 10px;
}

.empty--basket {
  height: 100%;
  margin-top: 25%;
}

.empty--basket>h4 {
  color: #82828285;
  margin-bottom: 20px;
}

.basket--item {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ebebeb;
}

.basket--item--img {
  width: 100px;
  height: 100px;
}

.basket--item--img>img {
  max-width: 100px;
  max-height: 100px;
}

.basket--item--title {
  font-size: 14px;
  font-weight: 700;
  color: #2c2c2c;
}

.basket--item--quantity {
  font-size: 16px;
}

.basket--item--quantity>span {
  font-size: 16px;
  font-weight: 500;
}

.basket--item--price {
  font-size: 16px;
}

.basket--item--price>span {
  font-size: 16px;
  font-weight: 500;
}

.modal-header>.checkout--btn {
  position: absolute;
  right: 0;
  margin-right: 50px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 7px;
  cursor: pointer;
  background-color: #db5246;
  color: white;
  border-radius: 30px;
  text-align: center;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* .error-container {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: montserrat, sans-serif;
} */

/* .big-text {
  font-size: 200px;
  font-weight: 900;
  font-family: sans-serif;
  background: url(https://cdn.pixabay.com/photo/2018/05/30/15/39/thunderstorm-3441687_960_720.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
} */

/* .small-text {
  font-family: montserrat, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
} */

/* .home-page-button {
  color: #fff;
  padding: 12px 36px;
  font-weight: 600;
  border: none;
  position: relative;
  font-family: "Raleway", sans-serif;
  display: inline-block;
  text-transform: uppercase;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
  margin: 2px;
  margin-top: 2px;
  background-image: linear-gradient(to right,
      #09b3ef 0%,
      #1e50e2 51%,
      #09b3ef 100%);
  background-size: 200% auto;
  flex: 1 1 auto;
  text-decoration: none;
}

.home-page-button:hover,
.home-page-button:focus {
  color: #ffffff;
  background-position: right center;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  text-decoration: none;
} */

/*********************************
6. Sidebar
*********************************/

.product_section_container {
  margin-top: 150px;
}

.product_section {}

.sidebar {
  float: left;
  width: 200px;
  padding-right: 17px;
}

.sidebar_section {
  padding-bottom: 35px;
  margin-bottom: 50px;
  border-bottom: solid 1px #ebebeb;
}

.sidebar_title h5 {
  font-size: 18px;
  margin-bottom: 21px;
}

.sidebar_categories li.active a {
  color: #fe4c50;
}

.sidebar_categories li.active:hover a {
  color: #fe4c50;
}

.sidebar_categories li a {
  font-size: 14px;
  line-height: 40px;
  color: #51545f;
}

.sidebar_categories li a:hover {
  color: #b5aec4;
}

.sidebar_categories li a span {
  margin-right: 5px;
}

#slider-range {
  margin-left: 7px !important;
}

#amount {
  margin-top: 8px;
  color: #1e1e27 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.filter_button {
  width: 100%;
  height: 30px;
  background: #1e1e27;
  text-align: center;
  margin-top: 25px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.filter_button:hover {
  background: #34343f;
}

.filter_button span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 30px;
  color: #ffffff;
}

/* .checkboxes li {
  height: 40px;
  line-height: 40px;
}

.checkboxes li.active i {
  color: #fe4c50;
  margin-right: 21px;
}

.checkboxes li.active i::after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  font-family: "FontAwesome";
  font-size: 10px;
  content: "\f00c";
  color: #ffffff;
}

.checkboxes {
  height: 200px;
  overflow: hidden;
}

.checkboxes.active {
  height: auto;
}

.checkboxes li i {
  position: relative;
  color: #b3b7c8;
  cursor: pointer;
  margin-right: 22px;
}

.checkboxes li span {
  font-family:"Regular" ;
  color: #51545f;
} */

.show_more {
  display: inline-block;
  cursor: pointer;
}

.show_more span {
  height: 40px;
  line-height: 40px;
  color: #fe4c50;
}

.show_more span:first-child {
  margin-right: 11px;
}

/*********************************
4.1 Product Sorting
*********************************/

.product_sorting_container_bottom {
  margin-top: 24px;
}

.product_sorting_container_bottom .product_sorting {
  float: right;
}

.product_sorting {
  display: inline-block;
}

.product_sorting>li {
  display: inline-block;
  position: relative;
  width: 185px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  border: solid 1px #ecedee;
  padding-left: 20px;
  padding-right: 7px;
  margin-right: 16px;
}

.product_sorting_container_bottom .product_sorting>li {
  width: 144px;
  margin-right: 0px;
  padding-right: 12px;
}

.product_sorting_container_bottom .product_sorting li span:nth-child(2) {
  margin-left: 24px;
}

.product_sorting li i {
  float: right;
  line-height: 40px;
  color: #51545f;
}

.product_sorting li ul {
  display: block;
  position: absolute;
  right: 0;
  top: 120%;
  margin: 0;
  width: 100%;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 15px 25px rgba(63, 78, 100, 0.15);
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}

.product_sorting>li:hover ul {
  visibility: visible;
  opacity: 1;
  top: calc(100% + 1px);
}

.product_sorting li ul li {
  display: block;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.product_sorting li ul li span {
  display: block;
  height: 40px;
  line-height: 40px;
  border-bottom: solid 1px #dddddd;
  color: #51545f;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}

.product_sorting li ul li span:hover {
  color: #b5aec4;
}

.product_sorting li ul li:last-child span {
  border-bottom: none;
}

.product_sorting li span:nth-child(2) {
  margin-left: 71px;
}

.pages {
  display: inline-block;
  float: right;
}

.product_sorting_container_bottom .pages {
  float: left;
}

.page_current {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  border: solid 1px #ecedee;
  margin-right: 16px;
}

.product_sorting_container_bottom .pages .page_current {
  background: #fe4c50;
  border: none;
}

.page_current span {
  color: #51545f;
}

.product_sorting_container_bottom .pages .page_current span {
  color: #ffffff;
}

.page_selection {
  display: block;
  position: absolute;
  right: 0;
  top: 120%;
  margin: 0;
  width: 100%;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 15px 25px rgba(63, 78, 100, 0.15);
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}

.page_selection li {
  display: block;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.page_selection li a {
  display: block;
  height: 40px;
  line-height: 40px;
  border-bottom: solid 1px #dddddd;
  color: #51545f;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}

.page_selection li a:hover {
  color: #b5aec4;
}

.page_current:hover .page_selection {
  visibility: visible;
  opacity: 1;
  top: calc(100% + 1px);
}

.page_total {
  display: inline-block;
  line-height: 40px;
  margin-right: 31px;
}

.page_total span {
  margin-right: 14px;
}

.page_next {
  display: inline-block;
  line-height: 40px;
  cursor: pointer;
}

.page_next:hover i {
  color: #b5aec4;
}

.page_next i {
  font-size: 18px;
  color: #51545f;
}

.showing_results {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  float: right;
  margin-right: 34px;
}

/*********************************
* Product Tags
*********************************/

.product-tags-container {
  display: flex;
  gap: 8px;
  /* Adjust the gap between tags as needed */
}

.product-tag {
  /* background-color: #e0e0e0; */
  /* padding: 4px 8px; */
  /* border-radius: 4px; */
}

.custom-product-tags-container .product-tag {
  position: relative;
  padding-right: 10px;
}

.custom-product-tags-container .product-tag::after {
  content: "/";
  position: absolute;
  right: 0;
}

.custom-product-tags-container .product-tag:last-child::after {
  content: "";
}

/*********************************
* Products Details Tabs
*********************************/

.product-details-table {
  width: 100%;
  border-collapse: collapse;
}

.tab {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.tab.active {
  /* background-color: #eee; */
  color: var(--secondary-color);
}

.content {
  padding: 10px;
}

.tableBody-border {
  border: 1px solid #ccc;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

}

.prev-next-button {
  position: absolute;
  top: 50%;
  /* Position at the vertical center */
  transform: translateY(-50%);
  left: 10px;
}

.productsSlider-ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  justify-content: center;
}

/*********************************
* Cart
*********************************/

.emptyCart {
  margin: 0 0 16px !important;
  border-radius: 1rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 1.875rem !important;
  padding-right: 1.875rem;
  position: relative;
  font-size: 1rem;
  line-height: 1.5rem;
  list-style: none outside;
  width: auto;
  word-wrap: break-word;
  /* background: #e5ecf1; */
  color: #26658c;
  width: 100%;
}


.cart-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
}

h1,
h2 {
  color: #333;
}

.cart-item {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.cart-item img {
  /* max-width: 100px; */
  margin-right: 20px;
}

.item-details {
  flex-grow: 1;
}

.remove-button {
  background-color: #dc3545;
  color: #fff;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.cart-summary {
  margin-top: 20px;
}

.checkout-button {
  background-color: #41a8bf;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.checkout-button:hover {
  background-color: #26658c;
}

.product-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  /* Limit to two lines */
  text-overflow: ellipsis;
  margin-bottom: 5px;
  /* Adjust as needed */
}

.cart-products {
  height: 70px;
}

.cart-checkout-btn {
  /* 25 */
  width: 80% !important;
}

.custom-no-border {
  border: none !important;
}

.cartItems-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cards {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.small-card {
  display: none;
}

.cartsfitImg {
  object-fit: contain;
}

.maxHeight {
  height: 92px;
}

/*********************************
* INPUT
*********************************/
.gray-bg {
  background-color: #f2f2f2 !important;
}

.country-Dropdown {
  width: 100%;
  padding: 6px;
  border-color: #ccc;
  border-radius: 5px;
}

.validation-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: red;
}

.validation-error-border {
  border: 2px solid red !important;
}

.place_order {
  float: none;
  display: inline-block;
  background-color: #064acb;
  border: 1px solid #064acb;
  border-radius: 4px;
  padding: 18px 50px;
  font-size: 18px;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  outline: 0;
}

.margin-left {
  margin-left: 5px;
}


/* pp */
.product-image {
  position: relative;
  overflow: hidden;
  width: 200px;
  /* Adjust the size of the product image */
}

.product-image img {
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.zoomed-image-container {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 20px;
  /* Adjust the space between the product image and zoomed image */
  width: 90%;
  /* Adjust the size of the zoomed image */
  overflow: hidden;
  display: flex;
  justify-content: center;
  z-index: 100;
}

.zoomed-image-container img {
  width: 100%;
}


/* Checkout page */
.margin {
  margin: 3rem;
}

/* React Image Magnify */
.custom-enlarged-container {
  width: 150% !important;
  /*height: 500px;*/
  z-index: 98;
}

.ReactImageMagnify__small-image-container {
  box-shadow: none;
}

/* .fluid {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: Arial;
  line-height: 1.3;
  font-size: 16px;
} */

/* ThankYou  */

.imageHeight {
  height: 250px;
}

.card-circle {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #dce3e6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2e3133;
  font-size: 0.875rem;
  cursor: pointer;
}

/* pagination */
.circle {
  width: 2rem;
  height: 2rem;
  background-color: #dce3e6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color) ;
  /* #2e3133 */
  font-size: 0.875rem;
  cursor: pointer;
}

.circle:hover {
  background-color: var(--logoColor);
  color:#fff;
}

.circle:hover .fa-shopping-bag {
  color: var(--White-color);
}

.active-page {
  background-color: var(--secondary-color);
  color: #fff;
}

.hide-button {
  display: none;
}

.select-dropdown {
  height: 2.2rem;
  line-height: 2.75rem;
  border-radius: 1rem;
  position: relative;
  padding-right: 2.625rem;
  padding-left: 1.375rem;
  /* display: block; */
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #8b9599;
  font-size: 1rem;
  background: #fff;
  border: 2px solid #dce3e6;
}

.box-height {
  line-height: 1.75rem !important;
  padding-right: 1.375rem !important;
  padding-left: 1.375rem !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
}

.pointer-on-hover {
  cursor: pointer;
}

/*  */
.slider-container {
  width: 100%;
  /* height: 400px; */
  /* Set your desired height */
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure images fill the container */
}

.overflowHidden {
  overflow: hidden;
}

.circle {
  display: inline-block;
  background-color: '#dce3e6';
  width: 30px;
  height: 30px;
  border-radius: '50%';
  text-align: center
}

.activeBtn {
  background-color: var(--secondary-color) !important;
  color: var(--White-color) !important;
}

.customBtn {
  padding: 0 1.5rem;
  color: var(--secondary-color);
  font-size: 1rem;
  line-height: 2.5rem;
  border-radius: 1rem;
  letter-spacing: -0.02rem;
  white-space: nowrap;
  position: relative;
  z-index: 9;
  font-weight: 700;
}

/*  */
.horizontal-product-display {
  overflow: auto;
  padding: 20px 0;
}

.product-list {
  display: flex;
  padding-bottom: 20px;
  /* Add padding to avoid horizontal scrollbar when scrolling */
}

.product-card {
  flex: 0 0 auto;
  width: 300px;
  /* Set width of each card */
  margin-right: 15px;
  /* Adjust spacing between cards */
  border: 1px solid #ccc;
  border-radius: 8px;
}

.product-image {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.product-details {
  padding: 15px;
}

.product-title {
  margin: 0;
  font-size: 18px;
  height: 65px;
  text-align: start;
}

.product-price {
  margin-top: 5px;
  font-size: 16px;
}

.weekly-products {
  padding: 50px 0;
  background-color: #f5f5f5;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
}

.product-list .product-item {
  margin-bottom: 40px;
  background-color: #fff;
  /* Example background color */
  border: 1px solid #ccc;
  /* Example border */
  border-radius: 10px;
  padding: 20px;
}

.product-item img {
  width: 100%;
  border-radius: 10px;
}

.product-title {
  /* margin-top: 20px; */
  font-size: 18px;
}


/*.container {*/
/*  max-width: 1200px;*/
/*  margin: 0 auto;*/
/*  padding: 0 20px;*/
/*}*/


.product-description {
  font-size: 14px;
}

@media (max-width: 991px) {
  .product-item {
    /* width: calc(50% - 20px); */
  }
}

@media (max-width: 575px) {
  .product-item {
    width: 100%;
  }
}

.footers {
  background-color: #6e0000;
  color: #bbb;

}

/* FAQ */
.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.faq-title {
  text-align: center;
}

.faq-list {
  margin-top: 20px;
}

.faq-item {
  /* border-bottom: 1px solid #ccc; */
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

}

.faq-question h4 {
  margin: 0;
  color: #0777b9
}

.faq-icon {
  transition: transform 0.3s ease;
}

.faq-icon-color {
  color: #0777b9
}

.faq-answer {
  padding: 10px 0;
}

.custom-container {
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
}


/* Scroll Bar  */
/* Style the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar handle on hover */
}







.slider-container {
  display: flex;
  justify-content: flex-start;
  /* Align images to the left */
  overflow: hidden;
  /* Hide overflow */
}

.single_product_thumbnails {
  width: 100%;
  margin: auto;
}

.thumbnail-container {
  position: relative;
  width: 100%;
  padding: 0px 25px;
}

.thumbnail {
  padding: 10px;
  box-sizing: border-box;
  width: auto !important;
}

.thumbnail-image {
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
}

.thumbnail-image.selected {
  border: 2px solid #4E97FD;
  border-radius: 4px;
  width: fit-content;
}

.slick-slide {
  margin: 0 !important;
  padding: 0 !important;
}

.thumbnail-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 4px;
}

.thumbnail-image img {
  /*max-width: 100%;*/
  height: auto;
}

.slick-prev:before,
.slick-next:before {
  color: #0777b9 !important;
}

.image-wrapper {
  position: relative;
  overflow: hidden;
}

.image-wrapper .large-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.image-wrapper.right .large-image {
  transform: translateX(100%);
}

.image-wrapper.left .large-image {
  transform: translateX(-100%);
}

.thumbnail-image img {
  max-width: 98px;
  height: auto;
  max-height: 98px;
  height: 98px;
  width: 98px;
}


.slider-container {
  display: inline-block;
  overflow: hidden;
}

.fluid__image-container div:first-child {
  height: 500px !important;
}






.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  /* color: var(--bs-nav-tabs-link-active-color); */
  background-color: none;
  border-color: none;
  border-top: none;
  border: none;
  border-bottom: 2px solid var(--secondary-color) !important;
}

x .custom-tabs .nav-item {
  text-align: center;
  margin-bottom: -1px;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
}

.custom-tabs .nav-link {
  /* border: 1px solid #dee2e6; */
  font-size: larger !important;
  padding: 25px !important;
  border-bottom: none;
  border-top: none;
  padding: 0.75rem 1rem;
  /* background-color: #f8f9fa; */
  color: #696969;
  border-radius: 0 !important;
  transition: none
    /* transition: background-color 0.5s, border-color 0.3s; */
}

.custom-tabs .nav-link.active {
  background-color: #fff;
  color: #495057;
  border-bottom: 2px solid var(--secondary-color) !important;

  /* border: none !important; */
  /* border-color: #dee2e6 #dee2e6 #fff; */
  /* font-weight: bold; */
}

.custom-tabs .nav-link:focus,
.custom-tabs .nav-link:hover {

  border-color: none;
  border-top: none;
  border: none !important;
  border-bottom: 2px solid var(--secondary-color) !important;
  color: inherit !important;

}

.tab-content-custom {
  padding: 1rem;
  /* border: 1px solid #dee2e6; */
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
  background-color: #fff;
  transition: max-height 0.5s ease-out, border-color 0.5s ease-out;
  overflow: hidden;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}

.tab-content-custom-peragraph {
  text-align: start;
}

.tab-content-custom-peragraph h1,
.tab-content-custom-peragraph h2,
.tab-content-custom-peragraph h3,
.tab-content-custom-peragraph h4,
.tab-content-custom-peragraph h5,
.tab-content-custom-peragraph h6 {
  font-weight: bold !important;
  letter-spacing: -0.03rem !important;
  font-family: "Bold" !important;
  font-size: 1.5rem !important;
}

.tab-content-custom-peragraph ul {
  list-style: disc;
}

.tab-content-custom-peragraph p>strong {
  color: var(--brand-color);
  font-family: "Bold" !important;
}

.tab-content-custom-peragraph ul>li {
  font-family: "Regular";
  color: #9B9B9B;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.74;

}

.tab-content-custom-peragraph ol>li {
  font-family: "Regular";
  color: #9B9B9B;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.74;

}

.tab-content-custom-peragraph p {
  /* font-family: "Inter-Regular";
  font-size: 1.125rem;
  line-height: 1.5rem; */
  font-family: "Regular";
  color: #9B9B9B;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.74;
}

ul {
  padding-left: 1.5rem;
}

ul li {
  margin-bottom: 0.5rem;
}


.custom-tabs {
  /* border: 1px solid #dee2e6; */
}

/* Scroll Bar  */
/* Carousel */
/* .carousel {
  height: 500px;
} */
/* .carousel-item img {
  object-fit: fill !important;
  height: 100%;
  max-height: 300px !important;
} */
.carousel-control-prev-icon {
  background-image: url('../images/icons_prev.png') !important;
  /* Replace '%23FF0000' with the desired color code */
}

.carousel-control-next-icon {
  background-image: url('../images/icons_next.png') !important;
}

.carousel-indicators [data-bs-target] {
  background-color: #000000 !important
}

/* Carousel */

/* Stripe */

.StripeElement {
  background-color: #f2f2f2;
  padding: 10px;
  border: '1px solid #ccc';
  border-radius: 10px;
}

/* Variants */
.variantsButton {
  padding: 5px;
  border: 1px solid;
  border-radius: 15px;
  min-width: 60px;
  text-align: center;
}

.select-dropdown:focus {
  border-color: var(--button-blue-color);
  /* Change to your desired color */
  outline: none;
  /* Optional: remove default outline */
}

/* Banner */
.banner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.banner-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  z-index: 1;
  color: #fff;
  width: -webkit-fill-available;
  margin-left: 20px;
  margin-right: 20px;

}

.text-left .banner-content {
  left: 10%;
  text-align: left;
  transform: translateY(-50%);
}

.text-center .banner-content {
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.text-right .banner-content {
  left: 90%;
  text-align: right;
  transform: translateY(-50%);
}

.banner-content h6,
.banner-content p {
  margin: 0;
}

.banner-text {
  color: #fff;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -1.5px;
}

.banner-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* .top-header-hide-show {
  display: none;
} */


/* IMAGE GALLERY */
.slick-list {
  margin-left: 0px !important;
}

/* IMAGE GALLERY */

/* RECENT PRODUCT */

.recentProduct .product-title {
  height: 70px;
  text-align: initial;
}

.recentProduct .brandLabel {
  text-align: initial;
}

.recentProduct .slick-next {
  top: 50% !important;
  right: -25px !important;
}

.recentProduct .slick-prev {
  top: 50% !important;
  left: -49px !important;
  right: 4px !important;
}

.recentProduct .slick-prev:before,
.recentProduct .slick-next:before {
  font-size: 45px;
}

.recentProduct .product-card {
  border: none;
}

/* RECENT PRODUCT */

/*Toast Css  */
.Toastify__toast-container--top-right {
  /* top: 10rem !important; */
}

/*Toast Css  */
/* load more button */
.load-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* Adjust margin as needed */
}

.load-more-button {
  padding: 5px 10px !important;
  /*background-color: #0777b9 !important;*/
  /* Button color */
  color: #fff !important;
  /* Text color */
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #af5d5f;
  /* Hover color */
}

.leftSidebarCategory {
  background: #F8F8F8;
  padding: 25px;
}

.leftSidebarPrice {
  background: #F8F8F8;
  padding: 25px;
}

.leftSidebarBrand {
  background: #F8F8F8;
  padding: 25px;
}

.color-options {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.color-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}

.color-circles {
  width: 30px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}

.selected {
  border: 2px solid #ccc;
}

.color-circle .inner-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
}

.size-options {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.size-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: lightgray;
  box-sizing: border-box;
}

.size-circle.selected {
  border: 2px solid #000;
}


@media (max-width: 768px) {
  .load-more-container {
    margin-top: 10px;
  }
}

/* load more button */

/* Drawer for filter category and brand in shop page */

@media (max-width: 991px) {
  .sidebar_hide {
    display: none;
  }

  .sidebarMobile {
    margin-top: 30px !important;
    margin-bottom: 20px !important;
  }

  .offcanvas-start {
    height: 100vh;
    /*top: 119px !important;*/
  }
}

/* @media (max-width: 767px) {
  .offcanvas-start {
    height: calc(100vh - 75px);
    top: 75px !important;
  }
} */

@media (min-width: 992px) {
  .sidebarMobile {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

/* Drawer for filter category and brand in shop page */

/* Commom class for all div */

.marginTopBottom {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

/* Commom class for all div */

.carouselCaptionHeading {
  font-size: 50px !important;
}

.download-access-shadow {
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
}

.footer-logo {
  width: 200px;
}

.download-app {
  width: 150px;
}

.text-light-color {
  color: #9b9b9b
}

.footer-links {
  color: #9b9b9b
}

.footer-links:hover {
  color: #4e97fd
}

.tab-content-custom {
  overflow: hidden;
  transition: height 0.5s ease, opacity 0.5s ease;
}

/*checkout*/

.form-group {
  text-align: left;
}

label {
  font-size: medium;
}

/* Toast  */
.Toastify__toast-container--top-right {
  top: auto !important;
}

.productImageModal {
  padding: 0 !important;
  background-color: black;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.productImageModal .productImageModalSlider {
  width: calc(100vw - 70px);
  height: 90vh;
  display: flex;
  /*margin-left: 15px;*/
}

.custom-modal-header {
  background-color: black !important;
}

.ProductImageHeader .modal-title {
  color: #fff;
}

.custom-modal-header .btn-close {
  background-color: #fff !important;
}

.product_image {
  overflow: hidden;
}

.product_image img {
  transition: transform 0.8s ease;
}

.product_image:hover img {
  transform: scale(1.1);
}

@media (max-width: 800px) {
  .productImageModal .productImageModalSlider img {
    height: -webkit-fill-available !important;
  }

  .productImageModal .productImageModalSlider {
    /*margin-left: 35px !important;*/
  }
}

.empty-carts {
  border: none;
  border-radius: 20px;
  color: #fff;
  min-width: 150px;
}

.shopping-btn {
  padding: 25px;
  border: none;
  color: #ffff;
  border-radius: 30px;
}
.closebtn{
  color: #000000;
}

.closebtn:hover{
  color: #fff;
}

.btn-border-radius {
  border-radius: 15px !important;
}


.empty-carts-image {
  width: 250px;
  height: auto;
  margin: 0 auto 30px;
}

.carts-img {
  border: 1px solid #eee;
  border-radius: 5px;
  width: 80px !important;
  padding: 10px;
}

.carts-coupon-input {
  padding: 25px !important;
  border-radius: 25px !important;
}

.text-underline-custom {
  text-decoration: underline;
  text-underline-offset: 4px;
  /* Adjust the value to control the space */
}

.table-heading {
  background-color: #f8f8f8 !important;
  color: #ffffff;
  /* Ensures the text is readable */
}

table {
  border: 1px solid #ccc;
}

.clearCarts {
  color: #ccc;
}

.clearCarts:hover {
  color: #000000;
}

.coupon-container {
  padding: 15px;
  background-color: #d5dfff;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
}

.w-max-content {
  width: max-content;
}

.clearFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fa;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.clearFilter p {
  margin: 0;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
}

/* login page */

.clearFilter .icon {
  color: var(--secondary-color);
  cursor: pointer;
  transition: transform 0.3s ease;
}
.clearFilter .icon:hover {
  transform: scale(1.1);
  color: var(--logoColor); 
}

.inline-box {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 29px 50px 48px;
  left: 50px;
}

.clearFilter .icon:hover {
  transform: scale(1.1);
}

.clearFilter .ml-auto {
  margin-left: auto;
}

.form-style {
  border-radius: 45px !important;
  height: 50px;
  border-color: white !important;
  background-color: white !important;
}

.text-top {
  margin-top: 30px;
}

.r-style {
  border-radius: 45px !important;
  height: 50px;
  border-color: #f8f8f8 !important;
  background-color: #f8f8f8 !important;
}

.forgot-password-style {
  border-radius: 45px !important;
  height: 50px;
  border-color: #f8f8f8 !important;
}

select {
  -webkit-appearance: initial !important;
}

.carousel-control-prev-icon {
  background-image: url("../images/arrow-chevron-left.svg") !important;
}

.carousel-control-next-icon {
  background-image: url("../images/arrow-chevron-right.svg") !important;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px !important;
  height: 10px !important;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
  border-radius: 100%;
}

.disabled-link {
  color: gray;
  cursor: not-allowed;
  text-decoration: none;
}

/* Products Description */
.tab-content-custom-paragraph-word-wrap {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

/* End */
.out-of-stock {
  padding: 7px;
  border: 1px solid;
  background-color: #fde8e8;
  color: red;
  border-radius: 15px;
}

.out-of-stock-circle {
  color: red;
  width: 2rem;
  height: 2rem;
  background-color: #dce3e6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
}

.offcanvas {
  height: 100vh;
  width: 320px !important;
}

/* product Details  */
.entry-product-categories {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ababab;
  margin: 0 0 5px;
}

.entry-title {
  font-size: 34px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  margin: 0 0 13px !important;
  font-family: "Regular";
}

.entry-product-section-heading {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.6;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
  margin: 43px 0 18px;
}

.qtyCheckButton {
  color: #8cc63e;
}

/* Checkout */
.checkout-payment-info-heading {
  /* padding-top: 46px; */
  /* border-top: 1px solid #eee; */
  margin-bottom: 32px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
}

.activePaymentsCard {
  background: #edf4fe;
  border-color: #edf4fe;
  color: #387aff;
}

.borderBottom {
  border-bottom: 1px solid #eee
}

.checkout-form-login-toggle,
.checkout-form-coupon-toggle {
  font-size: 16px;
  margin-bottom: 5px;
  color: #9B9B9B;
  font-family: "Regular" !important;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
}

.custom-input-container {
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px; /* Rounded corners for the input */
  background-color: #f7f7f7; /* Background color for the input container */
  display: flex;
  align-items: center;
}

.custom-input {
  width: 100%;
  padding: 10px 40px 10px 15px; /* Adjusted padding to make space for the icon */
  border: none;
  border-radius: 5px; /* Matching border-radius */
  background-color: transparent; /* Transparent background to show container color */
  outline: none; /* Remove the default focus outline */
  box-sizing: border-box;
  font-size: 16px;
}

.input-icon {
  position: absolute;
  right: 15px; /* Position the icon inside the input, on the right side */
  color: #888; /* Icon color */
  font-size: 16px;
}