.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-skeleton {
  width: 98%; 
  max-width: 98%; 
  display: flex;
  flex-direction: column;
  align-items: stretch; 
}
