.form-container {
    max-width: auto;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.row-style {
    margin-bottom: 20px;
}

.column-style {
    padding-left: 15px;
    padding-right: 15px;
}

.label-style {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

.input-wrapper {
    padding: 12px;
    border: 1px solid #f8f8f8;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border-radius: 45px !important;
    /* height: 50px; */
    border-color: #f8f8f8 !important;
    background-color: #f8f8f8 !important;
}

.input-style {
    width: 100%;
    font-size: 16px;
    color: #495057;
    background-color: transparent;
    border: none;
    outline: none;
}


.submit-button {
    width: 100%;
    background-color: #415da1;
    color: #fff;
    border-radius: 5px;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

/* Style for the submit button on hover */
.submit-button:hover {
    background-color: #5469d4;
}
