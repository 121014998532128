/* Global styles */
* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

strong {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #555;
}

ul {
  list-style: none;
  padding: 0;
}

header {
  box-shadow: 2px 9px 49px -17px rgba(0, 0, 0, 0.3);
  /*position: sticky;*/
  top: 0;
  width: 100%;
  z-index: 10000;
}
.header-fixed {
  box-shadow: 2px 9px 49px -17px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  /* animation: slideIn 1s ease-in-out;
  transition: transform 0.2s ease-in-out;; */
}

.header-visible {
  box-shadow: none;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 99;
  /* animation: slideOut 1s ease-in-out;
  transition: transform 0.2s ease-in-out; */
}

@keyframes slideOut {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: -100%;
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    top: -100%;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
.headerWhite{
  background-color: #fff !important;
}

.header-content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  color: #fff;
  padding: 10px 20px;
  transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.dropdown-toggle::after{
  color: #ababab;
}

.search-input::placeholder {
  color: #FFFFFF !important;
  font-size: 14px;
  opacity: 1;
}



.left-content,
.right-content {
  display: inline-flex;
  align-items: center;
}
.middle-content {
  flex: 1; 
  display: flex;
  align-items: center;
  justify-content: center;
}
.cutom-dropdown{
  background-color: transparent;
  border: none;
  color: aliceblue;
}
.custom-option{
  color: #555;
}

.icons-container {
  margin-left: 25px;
  display: flex;
  gap: 10px; 
}

.icon {
  padding: 10px;
  color: #fff;
  cursor: pointer;
}
.dropdown {
  position: relative;
  display: inline-block;
}


.dropdown-content.show {
  display: block;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-right-border{
  border-right: 1px solid #ccc;
}

.dropdown-toggle {
  cursor: pointer;
  padding: 10px;
}

.dropdown-content {
  position: absolute;
  /* top: 100%; */
  margin-top: 10px;
  /*right: 0;*/
  background-color: #ffffff;
  border-radius: 0 0 20px 20px; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-width: 160px;
  padding: 12px 16px;
  display: block;
}

.search-container {
  display: flex;

}

.search-input {
  border: none;
  padding: 8px;
  outline: none;
  background-color: transparent;
}

.search-icon {
  padding: 8px;
}

.search-icon i {
  color: #FFFFFF;
}
.horizontal-links {
  display: flex;
  list-style: none;
  padding: 0;
}

.horizontal-links li {
  margin-right: 20px; 
}

.horizontal-links li:last-child {
  margin-right: 0;
}

.horizontal-links li a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  padding: 5px 10px;
}

.horizontal-links li a:hover {
  color: #555;
}
.mobileMenu{
  display: none;
} 
.topBarFonts{
  /* font-size: smaller; */
  font-size:14px;
  color: #ababab;
}
.topBarFonts:hover{
  color: #000;
}
.topBarCenterText{
  font-size: 14px;
  font-weight: 400;
  line-height: 1.78;
  color: #696969;
}
.top-header-hide-show {
  display: none;
}
.custom-searc-input {
  border: none !important;
  border-bottom: 1px solid #415da1 !important;
  border-radius: 0px !important;
}
.custom-searc-input:focus {
  border-bottom: 1px solid #415da1 !important;
  outline: none !important; 
  box-shadow: none !important;
}
.cartsHeight{
  height: 30px;
  width: 30px;
}


@media screen and (max-width: 768px) {
 
  .nav-content {
    display: flex;
    justify-content: center; /* Center align navigation links */
  }
  .logo{
    max-width: 200px;
  }
  .topBarFonts{
    /* font-size: smaller; */
    font-size:14px;
    color: #ababab;
  }
  .displyHide{
    display: none ;
  }
  .carts-hide{
    display: none !important;
  }
}
/* Responsive styles */
@media (max-width: 991px) {
  .logo{
    max-width: 200px;
  }
  
}
@media (min-width: 768px) {
  .displaynone{
    display: none !important;
  }
  .hideSearch{
    display: none;
  }
}

@media (max-width: 768px) {
  .dropdown {
    display: none; /* Hide dropdown on smaller screens */
  }
  .hide-div {
    display: none; /* Hide left and right content on small screens */
  }
  /* .mobileMenu{
    display: block;
  } */
  .logo{
    max-width: 200px;
  }
  .bottomHeaderBG{
    display: contents !important;
    background-color: unset !important;
  }
  .hideSearch{
    display: block;
  }
}

@media (max-width: 1199px) {
  .mobileMenu{
    display: block;
  }
  .displyHide{
    display: none ;
  }
}